import * as React from 'react'

import { Button } from '../../../../main/components/button'
import { SVG } from '../../../../main/components/icon/svg'
import mapActionCreators from '../../store/action-creators'
import { useMapContext } from '../Map'

export function useZoomButtons() {
  const { treemap } = useMapContext()
  const zoomOut = React.useCallback(
    (ev: React.MouseEvent) => {
      if (!treemap) return
      ev.preventDefault()
      mapActionCreators.zoom(treemap, -1, treemap.width / 2, treemap.height / 2)
    },
    [treemap]
  )
  const zoomIn = React.useCallback(
    (ev: React.MouseEvent) => {
      if (!treemap) return
      ev.preventDefault()
      mapActionCreators.zoom(treemap, 1, treemap.width / 2, treemap.height / 2)
    },
    [treemap]
  )

  return { zoomIn, zoomOut }
}

export function Zoom() {
  const handlers = useZoomButtons()

  return (
    <>
      <Button
        theme="transparent"
        rounding="none"
        className="group h-10 w-12 border-l border-inherit"
        onClick={handlers.zoomOut}
      >
        <SVG width="24" height="24" className="m-auto opacity-50 group-hover:opacity-100">
          <rect x="4" y="10" width="16" height="4" />
        </SVG>
      </Button>
      <Button
        theme="transparent"
        rounding="none"
        className="group h-10 w-12 border-l border-inherit"
        onClick={handlers.zoomIn}
      >
        <SVG width="24" height="24" className="m-auto opacity-50 group-hover:opacity-100">
          <rect x="4" y="10" width="16" height="4" />
          <rect x="10" y="4" width="4" height="16" />
        </SVG>
      </Button>
    </>
  )
}
