import * as d3 from 'd3'

interface Dimensions {
  width: number
  height: number
}

export class Zoom<Datum> {
  zoomElement = document.createElement('canvas')
  zoomSelection = d3.select<HTMLCanvasElement, Datum>(this.zoomElement)
  behavior: d3.ZoomBehavior<HTMLCanvasElement, Datum>

  translateExtent: [width: number, height: number]
  zoomLevels: number[]

  constructor(dimensions: Dimensions, zoomLevels: number[]) {
    this.translateExtent = [dimensions.width, dimensions.height]
    this.zoomLevels = zoomLevels

    this.behavior = d3
      .zoom<HTMLCanvasElement, Datum>()
      .extent([[0, 0], this.translateExtent])
      .scaleExtent([this.zoomLevels[0], this.zoomLevels[this.zoomLevels.length - 1]])
      .translateExtent([[0, 0], this.translateExtent])
      .on('zoom', null)

    this.zoomSelection.call(this.behavior)
  }

  updateDimensions = (dimensions: Dimensions) => {
    this.translateExtent = [dimensions.width, dimensions.height]
    this.behavior.extent([[0, 0], this.translateExtent]).translateExtent([[0, 0], this.translateExtent])
  }

  getTransform = () => d3.zoomTransform(this.zoomElement)

  scale = (newZoom?: number, mapCenter?: { x: number; y: number }) => {
    if (typeof newZoom === 'number' && Number.isFinite(newZoom)) {
      if (mapCenter) {
        this.zoomSelection.call(this.behavior.scaleTo, newZoom, [mapCenter.x, mapCenter.y])
      } else {
        this.zoomSelection.call(this.behavior.scaleTo, newZoom)
      }
      return newZoom
    }

    return this.getTransform().k
  }

  translate = (args?: [x: number, y: number]): [x: number, y: number] => {
    const scale = this.scale()

    if (Array.isArray(args) && Number.isFinite(args[0]) && Number.isFinite(args[1])) {
      this.zoomSelection.call(this.behavior.translateBy, -(args[0] / scale), -(args[1] / scale))
      return [args[0], args[1]]
    }
    let t = this.getTransform()
    return [t.x, t.y]
  }

  translateAbs = ([x, y]: [x: number, y: number]) => {
    const scale = this.scale()
    this.zoomSelection.call(this.behavior.translateTo, -(x / scale), -(y / scale), [0, 0])
  }

  getNearestSize(currentZoom: number = this.scale()) {
    let nearestSize = this.zoomLevels[0]
    for (var i = 0, len = this.zoomLevels.length; i < len; i++) {
      const level = this.zoomLevels[i]
      if (level >= currentZoom) {
        nearestSize = level
        break
      }
    }

    return nearestSize
  }
}
