export enum AdsProvider {
  None,
  InvestingChannel,
  Freestar,
}

export function getAdsProvider() {
  if (FinvizSettings.hasUserPremium) return AdsProvider.None

  if (FinvizSettings.adsProvider === 2) {
    return AdsProvider.Freestar
  }

  return AdsProvider.InvestingChannel
}

/**
 * Tracks virtual page views to google analytics and freestar
 */
export function trackPageView(props: { pathname?: string; query?: string; freestarPageView?: boolean }) {
  const { pathname = window.location.pathname, query = window.location.search, freestarPageView = true } = props

  window.gtag?.('set', 'page_path', `${pathname}${query.length ? '?' : ''}${query.replace(/^\?/, '')}`)
  window.gtag?.('event', 'page_view')

  if (freestarPageView && getAdsProvider() === AdsProvider.Freestar) {
    window.freestar?.queue.push(() => freestar.trackPageview())
  }
}
