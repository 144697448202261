import * as d3 from 'd3'

import { PortfolioSymbolType } from '../../main/modules/portfolio/types'
import { getSymbolType } from '../../main/modules/portfolio/utils'
import { getUuid } from '../../main/util'
import { getTextFontFamily } from '../shared/getTextFontFamily'
import { gradientSmall, gradients } from '../shared/gradients'
import { fontSizeLineHeights, fontSizesWidths } from './constants/font'
import { ISettingsSection, scaleMinMax, scaleStepFormat } from './constants/settings'
import { ISettings, ISettingsSectionFont, ISettingsSectionHeader } from './constants/settings'
import LayoutGenerator from './layout-generator'
import {
  MapData,
  MapDataIndustry,
  MapDataNode,
  MapDataRow,
  MapDataSector,
  MapSubtypeId,
  MapTypeId,
  PerfData,
  Scale,
  ScaleId,
} from './types'
import * as mapUtils from './utils'
import { Zoom } from './zoom'

const fontFamily = getTextFontFamily()

class Treemap {
  width: number
  height: number
  version?: number
  scale: Scale
  countIndustryPerf: boolean
  countSectorPerf?: boolean
  nodes: MapDataNode[] = []
  sectors: MapDataSector[] = []
  industries: MapDataIndustry[] = []
  zoom: Zoom<MapDataNode>
  colorScale: (n?: number) => string
  settings: ISettings
  type: MapTypeId
  subtype: MapSubtypeId
  isSmall: boolean
  zoomLevels: number[]
  dataHash: string
  mapNodeId: string
  truncateNodeName: boolean

  constructor({
    countIndustryPerf = false,
    countSectorPerf = false,
    subtype = MapSubtypeId.DayPerf,
    isSmall = false,
    zoomLevels = mapUtils.getDefaultZoomLevels(),
    truncateNodeName = false,
    ...props
  }: {
    data: MapData
    width: number
    height: number
    version?: number
    scale: Scale
    countIndustryPerf?: boolean
    countSectorPerf?: boolean
    settings: ISettings
    type: MapTypeId
    subtype?: MapSubtypeId
    isSmall?: boolean
    zoomLevels?: number[]
    truncateNodeName?: boolean
    mapNodeId?: string
    dataHash: string
  }) {
    this.width = props.width
    this.height = props.height
    this.version = props.version
    this.settings = props.settings
    this.type = props.type
    this.scale = props.scale
    this.nodes = props.data.nodes
    this.sectors = props.data.sectors
    this.industries = props.data.industries
    this.subtype = subtype
    this.zoomLevels = zoomLevels
    this.isSmall = isSmall
    this.countIndustryPerf = countIndustryPerf
    this.countSectorPerf = countSectorPerf
    this.truncateNodeName = truncateNodeName
    this.mapNodeId = props.mapNodeId ?? getUuid()
    this.dataHash = props.dataHash

    this.zoom = new Zoom<MapDataNode>({ width: this.width, height: this.height }, this.zoomLevels)

    this.colorScale = this.getColorScale()

    if (this.countIndustryPerf) {
      this._updateIndustryPerf()
    }
    if (this.countSectorPerf) {
      this._updateSectorPerf()
    }
  }

  getIsSmall() {
    return this.isSmall
  }

  updateData(props: { width: number; height: number; data: MapData; scale: Scale; dataHash: string }) {
    this.width = props.width
    this.height = props.height
    this.nodes = props.data.nodes
    this.sectors = props.data.sectors
    this.industries = props.data.industries
    this.scale = props.scale
    this.dataHash = props.dataHash

    if (this.countIndustryPerf) {
      this._updateIndustryPerf()
    }
    if (this.countSectorPerf) {
      this._updateSectorPerf()
    }

    this.colorScale = this.getColorScale()
  }

  getScaleMinMax() {
    switch (this.scale.id) {
      case ScaleId.PortfolioPct:
      case ScaleId.PortfolioUsd:
        if (this.nodes.length === 0) return [0, 0]
        // get min/max week perf
        const minValuePerf = Math.abs(d3.min(this.nodes, (node: MapDataNode) => node.data?.perfWeek ?? 0))
        const maxValuePerf = Math.abs(d3.max(this.nodes, (node: MapDataNode) => node.data?.perfWeek ?? 0))
        const perfMax = Math.max(minValuePerf, maxValuePerf)
        // get min/max all time perf
        const minValueNode = Math.abs(d3.min(this.nodes, (node: MapDataNode) => node.perf)!)
        const maxValueNode = Math.abs(d3.max(this.nodes, (node: MapDataNode) => node.perf)!)
        const valueMax = Math.max(minValueNode, maxValueNode)
        // Use the weekly perf as a buffer, compute number of digits and round nicely
        const valueToUse = this.scale.id === ScaleId.PortfolioPct ? valueMax + perfMax : valueMax * (1 + perfMax / 100)
        // Sanity check
        if (Number.isNaN(valueToUse)) return [0, 0]
        const numberOfDigits = Math.max(Math.floor(Math.log10(Math.abs(valueToUse))), 0) + 1
        const roundedBound = Math.ceil(valueToUse / numberOfDigits) * numberOfDigits

        return [-roundedBound, roundedBound]
      default:
        return scaleMinMax[this.scale.id]
    }
  }

  getColorScale() {
    const [minDomain, maxDomain] = this.getScaleMinMax()
    let gradient = gradients[this.scale.id]
    if (this.getIsSmall() && this.scale.id === ScaleId.DayPerf) {
      gradient = gradientSmall
    }
    const linearScale = d3
      .scaleLinear()
      .domain([minDomain, maxDomain])
      .range([0, gradient.colors.length - 1])
    return (d?: number) => {
      if (typeof d === 'undefined' || !Number.isFinite(d)) {
        return gradient.nullColor
      }
      const min = Math.min(minDomain, maxDomain)
      const max = Math.max(minDomain, maxDomain)
      const value = Math.max(Math.min(d, max), min)

      const i = Math.round(linearScale(value))

      return gradient.colors[i]
    }
  }

  getParentSector(node: MapDataNode | MapDataIndustry): string {
    if (node.parent && !!node.parent.parent) return this.getParentSector(node.parent as MapDataIndustry)

    return node.name
  }

  /*
   * data = {
   *   nodes: {"AAPL":  1.5, "MSFT": -0.5}
   *   industries: {...}
   * }
   */
  updatePerf(data: PerfData) {
    this.dataHash = data.hash

    for (var i = 0; i < this.nodes.length; i++) {
      const nodeName = this.nodes[i].name

      if (Array.isArray(data.nodes)) {
        const parentSector = this.getParentSector(this.nodes[i])
        const updatedNode = data.nodes.find((node) => node.name === nodeName && node.data?.sector === parentSector)

        if (!updatedNode) continue

        this.nodes[i].perf = updatedNode.perf
        this.nodes[i].additional = updatedNode.additional
      } else {
        this.nodes[i].perf = data.nodes[nodeName]
        this.nodes[i].additional = data.additional[nodeName]
      }
    }

    if (this.countIndustryPerf) {
      this._updateIndustryPerf()
    } else {
      this._resetIndustryPerf()
    }

    if (this.countSectorPerf) {
      this._updateSectorPerf()
    }

    this.colorScale = this.getColorScale()
  }

  _resetIndustryPerf() {
    for (var i = 0; i < this.industries.length; i++) {
      this.industries[i].perf = undefined
    }
  }

  _updateIndustryPerf() {
    var industry, weightedPriceSum, marketCapSum, stock, marketCap, everyStockUndefined
    for (var i = 0; i < this.industries.length; i++) {
      industry = this.industries[i]
      weightedPriceSum = 0
      marketCapSum = 0
      everyStockUndefined = true
      for (var j = 0; j < industry.children.length; j++) {
        stock = industry.children[j]
        marketCap = stock.dx * stock.dy
        if (typeof stock.perf !== 'undefined' && stock.perf !== null) {
          weightedPriceSum += stock.perf * marketCap
          everyStockUndefined = false
        }
        marketCapSum += marketCap
      }
      if (!everyStockUndefined) {
        industry.perf = marketCapSum !== 0 ? weightedPriceSum / marketCapSum : 0
      }
    }
  }

  _updateSectorPerf() {
    var sector, industry, weightedPriceSum, marketCapSum, stock, marketCap
    for (var s = 0; s < this.sectors.length; s++) {
      sector = this.sectors[s]
      weightedPriceSum = 0
      marketCapSum = 0
      for (var i = 0; i < sector.children.length; i++) {
        industry = sector.children[i]
        for (var j = 0; j < industry.children.length; j++) {
          stock = industry.children[j]
          marketCap = stock.dx * stock.dy
          if (typeof stock.perf !== 'undefined' && stock.perf !== null) {
            weightedPriceSum += stock.perf * marketCap
          }
          marketCapSum += marketCap
        }
      }
      sector.perf = marketCapSum !== 0 ? weightedPriceSum / marketCapSum : 0
    }
  }

  _getNodeTopOffset(node: MapDataNode) {
    if (!this.isSmall && this.type === MapTypeId.World) return 0

    const isSmallSecMap = this.isSmall && this.type === MapTypeId.Sector
    // Industry
    let parent: MapDataRow = node.parent
    let leftPadding = this.settings.industry.padding.left
    let topPadding = this.settings.industry.padding.top + this.settings.industry.header.height

    if (isSmallSecMap) {
      // Sector
      parent = node.parent.parent
      leftPadding = this.settings.sector.padding.left
      topPadding = this.settings.sector.padding.top + this.settings.sector.header.height
    }

    const isFirstNodeInIndustry =
      Math.floor(parent.x + leftPadding) === node.x && Math.floor(parent.y + topPadding) === node.y
    const showIndustryHeader = LayoutGenerator.isNodeHeaderVisible(parent, this.settings)

    return isFirstNodeInIndustry && showIndustryHeader ? 6 : 0
  }

  getNodeText(node: MapDataNode) {
    let name = node.name
    const scale = this.zoom.getNearestSize()
    const fontSizes = this.settings.scaleFontSizes[scale]
    const nodeTopOffset = this._getNodeTopOffset(node)
    const nodeHeight = node.dy - nodeTopOffset

    let nodeNameFontSize = this.findMaxFontSizeForText(node.name, node.dx, nodeHeight, fontSizes)
    if (!nodeNameFontSize && this.truncateNodeName) {
      nodeNameFontSize = fontSizes[fontSizes.length - 1]
      name = this.getLongestText(
        name,
        nodeNameFontSize,
        Math.max(0, node.dx - this.settings.fontSizePadding[nodeNameFontSize] * 2)
      )
    } else if (!nodeNameFontSize) return null

    const nodeNameLineHeight = fontSizeLineHeights[nodeNameFontSize]
    const stepFormat = scaleStepFormat[this.scale.id]
    const format = stepFormat === '%N%' ? (node.dx > 32 ? stepFormat : '%N') : stepFormat

    let perfText = getSymbolType(node.name) === PortfolioSymbolType.Cash ? '' : (node.additional ?? '')
    if (perfText.length === 0 && node.perf !== undefined && Number.isFinite(node.perf)) {
      perfText = this.formatValue(node.perf.toFixed(2), scaleMinMax[this.scale.id], format)
    }

    const fontPairs =
      this.settings.fontSizePairs[nodeNameFontSize]?.filter((allowedSize) => fontSizes.includes(allowedSize)) ?? []

    let perfFontSize
    let perfLineHeight = 0
    if (fontPairs.length) {
      perfFontSize =
        fontPairs && this.findMaxFontSizeForText(perfText, node.dx, nodeHeight - nodeNameLineHeight, fontPairs)
      perfLineHeight = perfFontSize ? fontSizeLineHeights[perfFontSize] : 0
    }

    const textHeight = nodeNameLineHeight + perfLineHeight

    return {
      topOffset: node.dy - textHeight <= nodeTopOffset ? nodeTopOffset : 0,
      fontSize: nodeNameFontSize,
      perfText,
      perfFontSize,
      name,
    }
  }

  findMaxFontSizeForText(text: string, width: number, height: number, fontSizes: number[]) {
    if (!text.length) return

    return fontSizes.find(
      (fontSize) =>
        this.getLongestText(text, fontSize, Math.max(0, width - this.settings.fontSizePadding[fontSize] * 2)) ===
          text && fontSizeLineHeights[fontSize] < height
    )
  }

  getLongestText(text: string, fontSize: number, maxWidth: number) {
    if (maxWidth === 0) return ''
    const widths = fontSizesWidths[fontSize]
    let i = 0
    let width = 0

    while (i < text.length) {
      width += widths[text[i]] ?? widths['W']
      if (width > maxWidth) break
      i++
    }

    return text.substring(0, i)
  }

  getZoomLevels() {
    return this.zoomLevels
  }

  getNextZoomLevel() {
    var actualZoomLevel = this.zoom.scale()
    if (this.zoomLevels[this.zoomLevels.length - 1] === actualZoomLevel) {
      return actualZoomLevel
    }
    for (var i = 0; i < this.zoomLevels.length; i++) {
      if (this.zoomLevels[i] > actualZoomLevel) {
        return this.zoomLevels[i]
      }
    }

    return this.zoomLevels[0]
  }

  getPreviousZoomLevel() {
    var actualZoomLevel = this.zoom.scale()
    if (this.zoomLevels[0] === actualZoomLevel) {
      return actualZoomLevel
    }
    for (var i = this.zoomLevels.length; i >= 0; i--) {
      if (this.zoomLevels[i] < actualZoomLevel) {
        return this.zoomLevels[i]
      }
    }

    return this.zoomLevels[0]
  }

  getLastZoomLevel() {
    return this.zoomLevels[this.zoomLevels.length - 1]
  }

  formatValue(
    value: string,
    [min, max]: [min: number, max: number] = scaleMinMax[this.scale.id],
    format: string = scaleStepFormat[this.scale.id]
  ) {
    let stepFormat = format
    if (typeof format !== 'string') {
      stepFormat = format[value]
    }

    if (!stepFormat) return value

    const floatValue = parseFloat(value)
    const absValue = value.replace(/^-/, '')
    const isPositiveScaleOnly = Math.min(min, max) >= 0 && Math.max(min, max) >= 0
    let formated = stepFormat?.replace('%N', absValue) ?? absValue

    // Some formats might not include number (eg. Before/After earnings)
    if (!stepFormat || !stepFormat.includes('%N')) return formated

    if (floatValue > 0 && !isPositiveScaleOnly) return `+${formated}`

    if (floatValue < 0) return `-${formated}`

    return formated
  }

  renderStockNode(node: MapDataNode, context: CanvasRenderingContext2D) {
    // Draw background
    context.fillStyle = this.colorScale(node.perf)
    context.fillRect(node.x, node.y, node.dx - 1, node.dy - 1)

    const nodeText = this.getNodeText(node)

    if (!nodeText) return

    context.save()

    // Set text properties
    context.textBaseline = 'middle'
    context.textAlign = 'center'
    context.fillStyle = 'rgba(0, 0, 0, 0.5)'

    const nodeY = node.y + nodeText.topOffset
    const nodeDY = node.dy - nodeText.topOffset
    const textX = node.x + node.dx / 2
    let nameY = nodeY + nodeDY / 2
    let perfTextY = nameY + nodeText.fontSize / 2
    const zoom = this.zoom.scale()
    const offset = zoom > 1 ? (1 / zoom) * 1.2 : 1
    if (nodeText.perfFontSize) {
      perfTextY = nameY + nodeText.fontSize / 2
      nameY -= nodeText.perfFontSize / 2
      context.font = 'bold ' + nodeText.perfFontSize + 'px ' + fontFamily
      context.fillText(nodeText.perfText, textX + offset, perfTextY + offset)
    }

    context.font = 'bold ' + nodeText.fontSize + 'px ' + fontFamily
    context.fillText(nodeText.name, textX + offset, nameY + offset)

    context.fillStyle = 'rgba(255, 255, 255, 1)'

    if (nodeText.perfFontSize) {
      context.font = 'bold ' + nodeText.perfFontSize + 'px ' + fontFamily
      context.fillText(nodeText.perfText, textX, perfTextY)
    }

    context.font = 'bold ' + nodeText.fontSize + 'px ' + fontFamily
    context.fillText(nodeText.name, textX, nameY)
    context.restore()
  }

  getSpacingWithDefaults(spacing?: { top: number; right: number; bottom?: number; left: number }) {
    return {
      top: spacing?.top ?? 0,
      right: spacing?.right ?? 0,
      bottom: spacing?.bottom ?? 0,
      left: spacing?.left ?? 0,
    }
  }

  renderIndustryHeader({
    node,
    context,
    config,
    fill,
    parent,
  }: {
    node: MapDataRow
    context: CanvasRenderingContext2D
    config?: ISettingsSectionHeader
    parent?: ISettingsSection
    fill: string
  }) {
    const parrentPadding = this.getSpacingWithDefaults(parent?.padding)
    const margin = this.getSpacingWithDefaults(config?.margin)
    const x = node.x + parrentPadding.left + margin.left
    const y = node.y + parrentPadding.top + margin.top
    const width = node.dx - parrentPadding.left - margin.left - parrentPadding.right - margin.right
    const height = y + (config?.height ?? 0)

    context.fillStyle = fill
    context.strokeStyle = config?.border ?? this.settings.background
    context.beginPath()
    context.moveTo(x, y)
    context.lineTo(x, height)

    context.lineTo(node.x + 6, height)
    context.lineTo(node.x + 11, height + 5)
    context.lineTo(node.x + 16, height)

    context.lineTo(x + width, height)
    context.lineTo(x + width, y)
    context.lineTo(x - 0.5, y)
    context.fill()
    context.stroke()
  }

  renderNodeText({
    node,
    context,
    config,
    parent,
  }: {
    node: MapDataRow
    context: CanvasRenderingContext2D
    config?: ISettingsSectionFont
    parent?: ISettingsSection
  }) {
    if (!config) return

    const parrentPadding = this.getSpacingWithDefaults(parent?.padding)
    const padding = this.getSpacingWithDefaults(config?.padding)

    context.textBaseline = 'alphabetic'
    context.textAlign = 'left'
    context.fillStyle = config.color
    context.font = `${config.fontWeight} ${config.fontSize}px ${fontFamily}`

    const text = this.getLongestText(
      node.name.toUpperCase(),
      config.fontSize,
      node.dx - parrentPadding.left - padding.left - parrentPadding.right - padding.right
    )
    context.fillText(
      text,
      node.x + parrentPadding.left + padding.left,
      node.y + parrentPadding.top + padding.top + config.fontSize
    )
  }

  getNodeAtPosition(x: number, y: number): MapDataNode | undefined {
    var scale = this.zoom.scale()
    var [tx, ty] = this.zoom.translate()
    for (var i = 0, len = this.nodes.length; i < len; i++) {
      var d = this.nodes[i]
      if (
        d.x * scale + tx < x &&
        x < (d.x + d.dx + 1) * scale + tx &&
        d.y * scale + ty < y &&
        y < (d.y + d.dy + 1) * scale + ty &&
        d.parent
      ) {
        return d
      }
    }
  }

  getSectorAtPosition(x: number, y: number): MapDataSector | undefined {
    var scale = this.zoom.scale()
    var [tx, ty] = this.zoom.translate()
    for (var i = 0, len = this.sectors.length; i < len; i++) {
      var d = this.sectors[i]
      if (
        d.x * scale + tx < x &&
        x < (d.x + d.dx + 1) * scale + tx &&
        d.y * scale + ty < y &&
        y < (d.y + d.dy + 1) * scale + ty &&
        d.parent
      ) {
        return d
      }
    }
  }

  getIndustryAtPosition(x: number, y: number): MapDataIndustry | undefined {
    var scale = this.zoom.scale()
    var [tx, ty] = this.zoom.translate()
    for (var i = 0, len = this.industries.length; i < len; i++) {
      var d = this.industries[i]
      if (
        d.x * scale + tx < x &&
        x < (d.x + d.dx + 1) * scale + tx &&
        d.y * scale + ty < y &&
        y < (d.y + d.dy + 1) * scale + ty &&
        d.parent
      ) {
        return d
      }
    }
  }
}

export default Treemap
