// Based on Lato font with bold weight
export const fontSizesWidths: Record<number, Record<string | number, number>> = {
  4: {
    0: 2.32,
    1: 2.32,
    2: 2.32,
    3: 2.32,
    4: 2.32,
    5: 2.32,
    6: 2.32,
    7: 2.32,
    8: 2.32,
    9: 2.32,
    ' ': 0.772,
    '+': 2.32,
    '-': 1.436,
    '%': 3.212,
    '.': 0.9,
    a: 2.094,
    b: 2.28,
    c: 1.908,
    d: 2.28,
    e: 2.138,
    f: 1.402,
    g: 2.07,
    h: 2.274,
    i: 1.084,
    j: 1.076,
    k: 2.206,
    l: 1.084,
    m: 3.368,
    n: 2.274,
    o: 2.274,
    p: 2.262,
    q: 2.28,
    r: 1.634,
    s: 1.748,
    t: 1.548,
    u: 2.274,
    v: 2.134,
    w: 3.164,
    x: 2.16,
    y: 2.134,
    z: 1.878,
    A: 2.84,
    B: 2.606,
    C: 2.682,
    D: 2.99,
    E: 2.29,
    F: 2.246,
    G: 2.892,
    H: 3.024,
    I: 1.256,
    J: 1.748,
    K: 2.792,
    L: 2.068,
    M: 3.72,
    N: 3.024,
    O: 3.198,
    P: 2.5,
    Q: 3.198,
    R: 2.62,
    S: 2.146,
    T: 2.38,
    U: 2.912,
    V: 2.84,
    W: 4.186,
    X: 2.716,
    Y: 2.618,
    Z: 2.468,
  },
  6: {
    0: 3.48,
    1: 3.48,
    2: 3.48,
    3: 3.48,
    4: 3.48,
    5: 3.48,
    6: 3.48,
    7: 3.48,
    8: 3.48,
    9: 3.48,
    ' ': 1.158,
    '+': 3.48,
    '-': 2.154,
    '%': 4.818,
    '.': 1.35,
    a: 3.141,
    b: 3.42,
    c: 2.862,
    d: 3.42,
    e: 3.207,
    f: 2.103,
    g: 3.105,
    h: 3.411,
    i: 1.626,
    j: 1.614,
    k: 3.309,
    l: 1.626,
    m: 5.052,
    n: 3.411,
    o: 3.411,
    p: 3.393,
    q: 3.42,
    r: 2.451,
    s: 2.622,
    t: 2.322,
    u: 3.411,
    v: 3.201,
    w: 4.746,
    x: 3.24,
    y: 3.201,
    z: 2.817,
    A: 4.26,
    B: 3.909,
    C: 4.023,
    D: 4.485,
    E: 3.435,
    F: 3.369,
    G: 4.338,
    H: 4.536,
    I: 1.884,
    J: 2.622,
    K: 4.188,
    L: 3.102,
    M: 5.58,
    N: 4.536,
    O: 4.797,
    P: 3.75,
    Q: 4.797,
    R: 3.93,
    S: 3.219,
    T: 3.57,
    U: 4.368,
    V: 4.26,
    W: 6.279,
    X: 4.074,
    Y: 3.927,
    Z: 3.702,
  },
  8: {
    0: 4.64,
    1: 4.64,
    2: 4.64,
    3: 4.64,
    4: 4.64,
    5: 4.64,
    6: 4.64,
    7: 4.64,
    8: 4.64,
    9: 4.64,
    ' ': 1.544,
    '+': 4.64,
    '-': 2.872,
    '%': 6.424,
    '.': 1.8,
    a: 4.188,
    b: 4.56,
    c: 3.816,
    d: 4.56,
    e: 4.276,
    f: 2.804,
    g: 4.14,
    h: 4.548,
    i: 2.168,
    j: 2.152,
    k: 4.412,
    l: 2.168,
    m: 6.736,
    n: 4.548,
    o: 4.548,
    p: 4.524,
    q: 4.56,
    r: 3.268,
    s: 3.496,
    t: 3.096,
    u: 4.548,
    v: 4.268,
    w: 6.328,
    x: 4.32,
    y: 4.268,
    z: 3.756,
    A: 5.68,
    B: 5.212,
    C: 5.364,
    D: 5.98,
    E: 4.58,
    F: 4.492,
    G: 5.784,
    H: 6.048,
    I: 2.512,
    J: 3.496,
    K: 5.584,
    L: 4.136,
    M: 7.44,
    N: 6.048,
    O: 6.396,
    P: 5,
    Q: 6.396,
    R: 5.24,
    S: 4.292,
    T: 4.76,
    U: 5.824,
    V: 5.68,
    W: 8.372,
    X: 5.432,
    Y: 5.236,
    Z: 4.936,
  },
  9: {
    0: 5.22,
    1: 5.22,
    2: 5.22,
    3: 5.22,
    4: 5.22,
    5: 5.22,
    6: 5.22,
    7: 5.22,
    8: 5.22,
    9: 5.22,
    ' ': 1.737,
    '+': 5.22,
    '-': 3.231,
    '%': 7.227,
    '.': 2.025,
    a: 4.712,
    b: 5.13,
    c: 4.293,
    d: 5.13,
    e: 4.811,
    f: 3.155,
    g: 4.658,
    h: 5.117,
    i: 2.439,
    j: 2.421,
    k: 4.964,
    l: 2.439,
    m: 7.578,
    n: 5.117,
    o: 5.117,
    p: 5.09,
    q: 5.13,
    r: 3.677,
    s: 3.933,
    t: 3.483,
    u: 5.117,
    v: 4.802,
    w: 7.119,
    x: 4.86,
    y: 4.802,
    z: 4.226,
    A: 6.39,
    B: 5.864,
    C: 6.035,
    D: 6.728,
    E: 5.153,
    F: 5.054,
    G: 6.507,
    H: 6.804,
    I: 2.826,
    J: 3.933,
    K: 6.282,
    L: 4.653,
    M: 8.37,
    N: 6.804,
    O: 7.196,
    P: 5.625,
    Q: 7.196,
    R: 5.895,
    S: 4.829,
    T: 5.355,
    U: 6.552,
    V: 6.39,
    W: 9.419,
    X: 6.111,
    Y: 5.891,
    Z: 5.553,
  },
  10: {
    0: 5.8,
    1: 5.8,
    2: 5.8,
    3: 5.8,
    4: 5.8,
    5: 5.8,
    6: 5.8,
    7: 5.8,
    8: 5.8,
    9: 5.8,
    ' ': 1.93,
    '+': 5.8,
    '-': 3.59,
    '%': 8.03,
    '.': 2.25,
    a: 5.235,
    b: 5.7,
    c: 4.77,
    d: 5.7,
    e: 5.345,
    f: 3.505,
    g: 5.175,
    h: 5.685,
    i: 2.71,
    j: 2.69,
    k: 5.515,
    l: 2.71,
    m: 8.42,
    n: 5.685,
    o: 5.685,
    p: 5.655,
    q: 5.7,
    r: 4.085,
    s: 4.37,
    t: 3.87,
    u: 5.685,
    v: 5.335,
    w: 7.91,
    x: 5.4,
    y: 5.335,
    z: 4.695,
    A: 7.1,
    B: 6.515,
    C: 6.705,
    D: 7.475,
    E: 5.725,
    F: 5.615,
    G: 7.23,
    H: 7.56,
    I: 3.14,
    J: 4.37,
    K: 6.98,
    L: 5.17,
    M: 9.3,
    N: 7.56,
    O: 7.995,
    P: 6.25,
    Q: 7.995,
    R: 6.55,
    S: 5.365,
    T: 5.95,
    U: 7.28,
    V: 7.1,
    W: 10.465,
    X: 6.79,
    Y: 6.545,
    Z: 6.17,
  },
  11: {
    0: 6.38,
    1: 6.38,
    2: 6.38,
    3: 6.38,
    4: 6.38,
    5: 6.38,
    6: 6.38,
    7: 6.38,
    8: 6.38,
    9: 6.38,
    ' ': 2.123,
    '+': 6.38,
    '-': 3.949,
    '%': 8.833,
    '.': 2.475,
    a: 5.759,
    b: 6.27,
    c: 5.247,
    d: 6.27,
    e: 5.88,
    f: 3.856,
    g: 5.693,
    h: 6.254,
    i: 2.981,
    j: 2.959,
    k: 6.067,
    l: 2.981,
    m: 9.262,
    n: 6.254,
    o: 6.254,
    p: 6.221,
    q: 6.27,
    r: 4.494,
    s: 4.807,
    t: 4.257,
    u: 6.254,
    v: 5.869,
    w: 8.701,
    x: 5.94,
    y: 5.869,
    z: 5.165,
    A: 7.81,
    B: 7.167,
    C: 7.376,
    D: 8.223,
    E: 6.298,
    F: 6.177,
    G: 7.953,
    H: 8.316,
    I: 3.454,
    J: 4.807,
    K: 7.678,
    L: 5.687,
    M: 10.23,
    N: 8.316,
    O: 8.795,
    P: 6.875,
    Q: 8.795,
    R: 7.205,
    S: 5.902,
    T: 6.545,
    U: 8.008,
    V: 7.81,
    W: 11.512,
    X: 7.469,
    Y: 7.2,
    Z: 6.787,
  },
  12: {
    0: 6.96,
    1: 6.96,
    2: 6.96,
    3: 6.96,
    4: 6.96,
    5: 6.96,
    6: 6.96,
    7: 6.96,
    8: 6.96,
    9: 6.96,
    ' ': 2.316,
    '+': 6.96,
    '-': 4.308,
    '%': 9.636,
    '.': 2.7,
    a: 6.282,
    b: 6.84,
    c: 5.724,
    d: 6.84,
    e: 6.414,
    f: 4.206,
    g: 6.21,
    h: 6.822,
    i: 3.252,
    j: 3.228,
    k: 6.618,
    l: 3.252,
    m: 10.104,
    n: 6.822,
    o: 6.822,
    p: 6.786,
    q: 6.84,
    r: 4.902,
    s: 5.244,
    t: 4.644,
    u: 6.822,
    v: 6.402,
    w: 9.492,
    x: 6.48,
    y: 6.402,
    z: 5.634,
    A: 8.52,
    B: 7.818,
    C: 8.046,
    D: 8.97,
    E: 6.87,
    F: 6.738,
    G: 8.676,
    H: 9.072,
    I: 3.768,
    J: 5.244,
    K: 8.376,
    L: 6.204,
    M: 11.16,
    N: 9.072,
    O: 9.594,
    P: 7.5,
    Q: 9.594,
    R: 7.86,
    S: 6.438,
    T: 7.14,
    U: 8.736,
    V: 8.52,
    W: 12.558,
    X: 8.148,
    Y: 7.854,
    Z: 7.404,
  },
  13: {
    0: 7.54,
    1: 7.54,
    2: 7.54,
    3: 7.54,
    4: 7.54,
    5: 7.54,
    6: 7.54,
    7: 7.54,
    8: 7.54,
    9: 7.54,
    ' ': 2.509,
    '+': 7.54,
    '-': 4.667,
    '%': 10.439,
    '.': 2.925,
    a: 6.806,
    b: 7.41,
    c: 6.201,
    d: 7.41,
    e: 6.949,
    f: 4.557,
    g: 6.728,
    h: 7.391,
    i: 3.523,
    j: 3.497,
    k: 7.17,
    l: 3.523,
    m: 10.946,
    n: 7.391,
    o: 7.391,
    p: 7.352,
    q: 7.41,
    r: 5.311,
    s: 5.681,
    t: 5.031,
    u: 7.391,
    v: 6.936,
    w: 10.283,
    x: 7.02,
    y: 6.936,
    z: 6.104,
    A: 9.23,
    B: 8.47,
    C: 8.717,
    D: 9.718,
    E: 7.443,
    F: 7.3,
    G: 9.399,
    H: 9.828,
    I: 4.082,
    J: 5.681,
    K: 9.074,
    L: 6.721,
    M: 12.09,
    N: 9.828,
    O: 10.394,
    P: 8.125,
    Q: 10.394,
    R: 8.515,
    S: 6.975,
    T: 7.735,
    U: 9.464,
    V: 9.23,
    W: 13.605,
    X: 8.827,
    Y: 8.509,
    Z: 8.021,
  },
  14: {
    0: 8.12,
    1: 8.12,
    2: 8.12,
    3: 8.12,
    4: 8.12,
    5: 8.12,
    6: 8.12,
    7: 8.12,
    8: 8.12,
    9: 8.12,
    ' ': 2.702,
    '+': 8.12,
    '-': 5.026,
    '%': 11.242,
    '.': 3.15,
    a: 7.329,
    b: 7.98,
    c: 6.678,
    d: 7.98,
    e: 7.483,
    f: 4.907,
    g: 7.245,
    h: 7.959,
    i: 3.794,
    j: 3.766,
    k: 7.721,
    l: 3.794,
    m: 11.788,
    n: 7.959,
    o: 7.959,
    p: 7.917,
    q: 7.98,
    r: 5.719,
    s: 6.118,
    t: 5.418,
    u: 7.959,
    v: 7.469,
    w: 11.074,
    x: 7.56,
    y: 7.469,
    z: 6.573,
    A: 9.94,
    B: 9.121,
    C: 9.387,
    D: 10.465,
    E: 8.015,
    F: 7.861,
    G: 10.122,
    H: 10.584,
    I: 4.396,
    J: 6.118,
    K: 9.772,
    L: 7.238,
    M: 13.02,
    N: 10.584,
    O: 11.193,
    P: 8.75,
    Q: 11.193,
    R: 9.17,
    S: 7.511,
    T: 8.33,
    U: 10.192,
    V: 9.94,
    W: 14.651,
    X: 9.506,
    Y: 9.163,
    Z: 8.638,
  },
  18: {
    0: 10.44,
    1: 10.44,
    2: 10.44,
    3: 10.44,
    4: 10.44,
    5: 10.44,
    6: 10.44,
    7: 10.44,
    8: 10.44,
    9: 10.44,
    ' ': 3.474,
    '+': 10.44,
    '-': 6.462,
    '%': 14.454,
    '.': 4.05,
    a: 9.423,
    b: 10.26,
    c: 8.586,
    d: 10.26,
    e: 9.621,
    f: 6.309,
    g: 9.315,
    h: 10.233,
    i: 4.878,
    j: 4.842,
    k: 9.927,
    l: 4.878,
    m: 15.156,
    n: 10.233,
    o: 10.233,
    p: 10.179,
    q: 10.26,
    r: 7.353,
    s: 7.866,
    t: 6.966,
    u: 10.233,
    v: 9.603,
    w: 14.238,
    x: 9.72,
    y: 9.603,
    z: 8.451,
    A: 12.78,
    B: 11.727,
    C: 12.069,
    D: 13.455,
    E: 10.305,
    F: 10.107,
    G: 13.014,
    H: 13.608,
    I: 5.652,
    J: 7.866,
    K: 12.564,
    L: 9.306,
    M: 16.74,
    N: 13.608,
    O: 14.391,
    P: 11.25,
    Q: 14.391,
    R: 11.79,
    S: 9.657,
    T: 10.71,
    U: 13.104,
    V: 12.78,
    W: 18.837,
    X: 12.222,
    Y: 11.781,
    Z: 11.106,
  },
  20: {
    0: 11.6,
    1: 11.6,
    2: 11.6,
    3: 11.6,
    4: 11.6,
    5: 11.6,
    6: 11.6,
    7: 11.6,
    8: 11.6,
    9: 11.6,
    ' ': 3.86,
    '+': 11.6,
    '-': 7.18,
    '%': 16.06,
    '.': 4.5,
    a: 10.47,
    b: 11.4,
    c: 9.54,
    d: 11.4,
    e: 10.69,
    f: 7.01,
    g: 10.35,
    h: 11.37,
    i: 5.42,
    j: 5.38,
    k: 11.03,
    l: 5.42,
    m: 16.84,
    n: 11.37,
    o: 11.37,
    p: 11.31,
    q: 11.4,
    r: 8.17,
    s: 8.74,
    t: 7.74,
    u: 11.37,
    v: 10.67,
    w: 15.82,
    x: 10.8,
    y: 10.67,
    z: 9.39,
    A: 14.2,
    B: 13.03,
    C: 13.41,
    D: 14.95,
    E: 11.45,
    F: 11.23,
    G: 14.46,
    H: 15.12,
    I: 6.28,
    J: 8.74,
    K: 13.96,
    L: 10.34,
    M: 18.6,
    N: 15.12,
    O: 15.99,
    P: 12.5,
    Q: 15.99,
    R: 13.1,
    S: 10.73,
    T: 11.9,
    U: 14.56,
    V: 14.2,
    W: 20.93,
    X: 13.58,
    Y: 13.09,
    Z: 12.34,
  },
  24: {
    0: 13.92,
    1: 13.92,
    2: 13.92,
    3: 13.92,
    4: 13.92,
    5: 13.92,
    6: 13.92,
    7: 13.92,
    8: 13.92,
    9: 13.92,
    ' ': 4.632,
    '+': 13.92,
    '-': 8.616,
    '%': 19.272,
    '.': 5.4,
    a: 12.564,
    b: 13.68,
    c: 11.448,
    d: 13.68,
    e: 12.828,
    f: 8.412,
    g: 12.42,
    h: 13.644,
    i: 6.504,
    j: 6.456,
    k: 13.236,
    l: 6.504,
    m: 20.208,
    n: 13.644,
    o: 13.644,
    p: 13.572,
    q: 13.68,
    r: 9.804,
    s: 10.488,
    t: 9.288,
    u: 13.644,
    v: 12.804,
    w: 18.984,
    x: 12.96,
    y: 12.804,
    z: 11.268,
    A: 17.04,
    B: 15.636,
    C: 16.092,
    D: 17.94,
    E: 13.74,
    F: 13.476,
    G: 17.352,
    H: 18.144,
    I: 7.536,
    J: 10.488,
    K: 16.752,
    L: 12.408,
    M: 22.32,
    N: 18.144,
    O: 19.188,
    P: 15,
    Q: 19.188,
    R: 15.72,
    S: 12.876,
    T: 14.28,
    U: 17.472,
    V: 17.04,
    W: 25.116,
    X: 16.296,
    Y: 15.708,
    Z: 14.808,
  },
  30: {
    0: 17.4,
    1: 17.4,
    2: 17.4,
    3: 17.4,
    4: 17.4,
    5: 17.4,
    6: 17.4,
    7: 17.4,
    8: 17.4,
    9: 17.4,
    ' ': 5.79,
    '+': 17.4,
    '-': 10.77,
    '%': 24.09,
    '.': 6.75,
    a: 15.705,
    b: 17.1,
    c: 14.31,
    d: 17.1,
    e: 16.035,
    f: 10.515,
    g: 15.525,
    h: 17.055,
    i: 8.13,
    j: 8.07,
    k: 16.545,
    l: 8.13,
    m: 25.26,
    n: 17.055,
    o: 17.055,
    p: 16.965,
    q: 17.1,
    r: 12.255,
    s: 13.11,
    t: 11.61,
    u: 17.055,
    v: 16.005,
    w: 23.73,
    x: 16.2,
    y: 16.005,
    z: 14.085,
    A: 21.3,
    B: 19.545,
    C: 20.115,
    D: 22.425,
    E: 17.175,
    F: 16.845,
    G: 21.69,
    H: 22.68,
    I: 9.42,
    J: 13.11,
    K: 20.94,
    L: 15.51,
    M: 27.9,
    N: 22.68,
    O: 23.985,
    P: 18.75,
    Q: 23.985,
    R: 19.65,
    S: 16.095,
    T: 17.85,
    U: 21.84,
    V: 21.3,
    W: 31.395,
    X: 20.37,
    Y: 19.635,
    Z: 18.51,
  },
  36: {
    0: 20.88,
    1: 20.88,
    2: 20.88,
    3: 20.88,
    4: 20.88,
    5: 20.88,
    6: 20.88,
    7: 20.88,
    8: 20.88,
    9: 20.88,
    ' ': 6.948,
    '+': 20.88,
    '-': 12.924,
    '%': 28.908,
    '.': 8.1,
    a: 18.846,
    b: 20.52,
    c: 17.172,
    d: 20.52,
    e: 19.242,
    f: 12.618,
    g: 18.63,
    h: 20.466,
    i: 9.756,
    j: 9.684,
    k: 19.854,
    l: 9.756,
    m: 30.312,
    n: 20.466,
    o: 20.466,
    p: 20.358,
    q: 20.52,
    r: 14.706,
    s: 15.732,
    t: 13.932,
    u: 20.466,
    v: 19.206,
    w: 28.476,
    x: 19.44,
    y: 19.206,
    z: 16.902,
    A: 25.56,
    B: 23.454,
    C: 24.138,
    D: 26.91,
    E: 20.61,
    F: 20.214,
    G: 26.028,
    H: 27.216,
    I: 11.304,
    J: 15.732,
    K: 25.128,
    L: 18.612,
    M: 33.48,
    N: 27.216,
    O: 28.782,
    P: 22.5,
    Q: 28.782,
    R: 23.58,
    S: 19.314,
    T: 21.42,
    U: 26.208,
    V: 25.56,
    W: 37.674,
    X: 24.444,
    Y: 23.562,
    Z: 22.212,
  },
}

export const fontSizeLineHeights: Record<number, number> = {
  4: 5,
  6: 7,
  8: 10,
  9: 11,
  10: 12,
  11: 13,
  12: 14,
  13: 15,
  14: 20,
  18: 21,
  20: 24,
  24: 28,
  30: 35,
  36: 42,
}
