import * as React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { Loading } from '../../../main/components/loading'
import { QueryClientContext } from '../../shared/components/QueryClientContext'
import { MapContextProvider } from './Map'

export function MapContext(props: React.PropsWithChildren<any>) {
  return (
    <MapContextProvider>
      <QueryClientContext>
        <React.Suspense
          fallback={
            <div className="flex h-110 items-center justify-center">
              <Loading />
            </div>
          }
        >
          <BrowserRouter>{props.children}</BrowserRouter>
        </React.Suspense>
      </QueryClientContext>
    </MapContextProvider>
  )
}
