import classnames from 'classnames'
import * as React from 'react'

export type SVGProps = React.SVGProps<SVGSVGElement>

export function SVG({ className, width = 16, height = width, ...props }: SVGProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      className={classnames('pointer-events-none block shrink-0 fill-current', className)}
      width={width}
      height={height}
    />
  )
}
