import { useLocation } from 'react-router-dom'

import { MAP_TYPES } from '../constants/constants'
import { MapSubtypeId } from '../types'

/**
 * Hook which gets the current map type and subtype from the URL
 */
export function useMapType() {
  const query = new URLSearchParams(useLocation().search)
  const typeFromQuery = query.get('t')
  const subtypeFromQuery = query.get('st')

  const type = MAP_TYPES.find((t) => t.type === typeFromQuery) ?? MAP_TYPES[0]
  const subtypes = type.subtypes.flatMap((type) => type.items)
  const subtype =
    subtypes.find((subtype) => subtype.value === subtypeFromQuery) ??
    subtypes.find((subtype) => subtype.value === MapSubtypeId.DayPerf) ??
    subtypes[0]

  return { type, subtype }
}
