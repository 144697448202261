import { useMutation } from '@tanstack/react-query'
import * as React from 'react'

import { getFormattedDateString } from '../../../header/utils'
import { Share as ShareDialog } from '../../../shared/components/Share'
import { drawShareMap } from '../../../shared/drawShareMap'
import * as api from '../../services/api'
import mapStore from '../../store/mapStore'
import { MapSubtype, MapType } from '../../types'
import { useMapContext } from '../Map'

interface ShareMapProps {
  mapType: MapType
  subtype: MapSubtype
  className?: string
}

export function Share(props: ShareMapProps) {
  const { treemap } = useMapContext()
  const uploadImage = useMutation(api.uploadMap)

  const onShareClick = React.useCallback(async () => {
    if (!treemap) return
    const canvas = mapStore.getPublishCanvas()
    const data = await drawShareMap(canvas, [props.mapType.name, props.subtype.label, getFormattedDateString()])

    if (data) {
      uploadImage.mutate({ type: treemap.type, subtype: treemap.subtype, data })
    }
  }, [props.mapType, props.subtype, treemap, uploadImage])

  return <ShareDialog className={props.className} onShareClick={onShareClick} state={uploadImage} disabled={!treemap} />
}
