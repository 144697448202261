import classnames from 'classnames'
import * as React from 'react'

import { Button, ButtonHTMLProps, ButtonProps } from '../../../main/components/button'
import { Input, InputHTMLProps, InputProps } from '../../../main/components/input'
import MapActionCreators from '../store/action-creators'
import { MapDataNode } from '../types'
import { useMapContext } from './Map'

function filterItems(stocks: MapDataNode[] = [], input: string) {
  return stocks
    .filter(function (stock) {
      return stock.name.indexOf(input) === 0 || (stock.description || '').toUpperCase().indexOf(input) === 0
    })
    .sort(function (a, b) {
      if (a.name === b.name) {
        return 0
      }
      return a.name < b.name ? -1 : 1
    })
    .slice(0, 10)
}

export function useSearch() {
  const { treemap } = useMapContext()
  const [value, setValue] = React.useState('')
  const [results, setResults] = React.useState(filterItems(treemap?.nodes, ''))

  React.useEffect(() => {
    if (treemap) {
      setResults(filterItems(treemap.nodes, ''))
      setValue('')
    }
  }, [treemap])

  const onInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target.value.toUpperCase()
      setValue(input)
      setResults(filterItems(treemap?.nodes, input))
    },
    [treemap]
  )

  const onItemClick = React.useCallback(
    (node: MapDataNode) => {
      if (!treemap) return

      const cx = (node.x + node.x + node.dx) / 2,
        cy = (node.y + node.y + node.dy) / 2,
        diffX = cx - treemap.width / 2,
        diffY = cy - treemap.height / 2

      MapActionCreators.zoomAndTranslate(treemap, treemap.getLastZoomLevel(), cx + diffX, cy + diffY)
      MapActionCreators.setHoveredNode(treemap.mapNodeId, node)
    },
    [treemap]
  )

  const onResultMouseLeave = React.useCallback(() => {
    if (!treemap) return
    MapActionCreators.setHoveredNode(treemap.mapNodeId, undefined)
  }, [treemap])

  return {
    value,
    results,
    onInputChange,
    onItemClick,
    onResultMouseLeave,
  }
}

export function SearchInput(props: InputProps & Omit<InputHTMLProps, 'ref'>) {
  return (
    <Input
      theme="none"
      rounding="none"
      placeholder="Quick search ticker"
      leftContent="search"
      onChange={props.onChange}
      value={props.value}
      {...props}
    />
  )
}

interface SearchResultProps extends ButtonProps, Omit<ButtonHTMLProps, 'onClick' | 'onMouseLeave'> {
  result: MapDataNode
  onClick: (node: MapDataNode) => void
  onMouseLeave: (e: React.MouseEvent) => void
}

export function SearchResult({ result, onClick, onMouseLeave, ...props }: Omit<SearchResultProps, 'as' | 'ref'>) {
  return (
    <li onMouseLeave={onMouseLeave}>
      <Button
        {...props}
        theme="transparent"
        size="none"
        className={classnames(props.className, 'h-5 w-full')}
        contentClass={classnames(props.className, 'flex overflow-hidden text-3xs')}
        onClick={() => onClick(result)}
      >
        <span className="w-11 shrink-0 font-bold">{result.name}</span>
        <span className="truncate">{result.description}</span>
      </Button>
    </li>
  )
}
