import * as React from 'react'

import { Button } from '../../../../main/components/button'
import { isIpad, isMobile } from '../../../shared/isMobile'
import { EXPANDED_MAP_BODY_CLASSS } from '../../constants/constants'
import mapActionCreators from '../../store/action-creators'
import { MapDataRoot } from '../../types'
import * as mapUtils from '../../utils'
import { useMapContext } from '../Map'

interface ExpandProps {
  data?: MapDataRoot
  className?: string
}

export function Expand(props: ExpandProps) {
  const { treemap, generator } = useMapContext()

  const onExpand = React.useCallback(() => {
    if (!treemap || !generator || !props.data) return

    if (document.body.classList.contains(EXPANDED_MAP_BODY_CLASSS)) {
      document.body.classList.remove(EXPANDED_MAP_BODY_CLASSS)
    } else {
      window.gtag?.('event', 'map-expand', { event_category: 'maps' })
      document.body.classList.add(EXPANDED_MAP_BODY_CLASSS)
    }

    const containerElement = document.getElementById('body')
    const size = mapUtils.getSize(containerElement, treemap.type, treemap.isSmall)

    if (size.width === treemap.width && size.height === treemap.height) return

    treemap.zoom.scale(1)
    generator.width = size.width
    generator.height = size.height
    const layout = generator.getLayout(props.data)
    mapActionCreators.updateLayout(treemap, {
      width: generator.width,
      height: generator.height,
      data: layout,
      scale: treemap.scale,
      dataHash: treemap.dataHash,
    })

    window.scrollTo(0, 0)
  }, [treemap, generator, props.data])

  if (isMobile() && !isIpad()) return null

  return (
    <Button className={props.className} theme="transparent" leftContent="fullscreen" onClick={onExpand}>
      Fullscreen
    </Button>
  )
}
