import * as ReactDOM from 'react-dom/client'

import App, { MapInitProps } from './App'
import { MapContext } from './components/MapContext'

window['FinvizInitCanvas'] = async function initCanvas(props: MapInitProps) {
  const rootEl = document.getElementById('root')

  const root = ReactDOM.createRoot(rootEl!)
  root.render(
    <MapContext>
      <App {...props} />
    </MapContext>
  )
}
