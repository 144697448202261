import classnames from 'classnames'
import { NavLink } from 'react-router-dom'

import { Delayed } from '../../../main/components/delayed'
import { Spinner } from '../../../main/components/spinner'
import * as tracking from '../../shared/tracking'
import { MAP_TYPES } from '../constants/constants'
import { MapSubtypeId, MapTypeId } from '../types'

interface MapTypeProps {
  activeType: MapTypeId
  activeSubtype: MapSubtypeId
  isLoading: boolean
  linkClassName?: string
}

export function MapTypeList(props: MapTypeProps) {
  const linkClass = props.linkClassName ?? ''

  return (
    <ul className="mt-2 divide-y divide-[#363a46]">
      {MAP_TYPES.map((map) => {
        const isActiveTreemap = props.activeType === map.type
        const query = new URLSearchParams({ t: map.type })
        if (props.activeSubtype !== MapSubtypeId.DayPerf) {
          query.append('st', props.activeSubtype)
        }

        return (
          <li key={map.type}>
            <NavLink
              to={`/map.ashx?${query}`}
              className={() =>
                classnames('text-xs leading-7', {
                  [linkClass]: !isActiveTreemap,
                  'text-white font-bold': isActiveTreemap,
                })
              }
              onClick={() => {
                document.title = map.pageTitle
                tracking.trackPageView({ query: `t=${map.type}` })
              }}
            >
              <span className="flex items-center">
                <span className="flex-1">{map.name}</span>
                {isActiveTreemap && props.isLoading && (
                  <Delayed>
                    <Spinner width={16} />
                  </Delayed>
                )}
              </span>
            </NavLink>
          </li>
        )
      })}
    </ul>
  )
}
