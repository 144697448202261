import * as React from 'react'

import * as tracking from '../tracking'

export const IC_PUBLISHER_ID = 'df0d0d52-cc7f-11e8-82a5-0abbb61c4a6a'

enum AdTagName {
  IC_TickerInContent = 'IC_D_3x3',
  IC_MapsLeftRail = 'IC_D_125x125',
  IC_Statements = 'IC_D_300x60',
  IC_Groups = 'IC_D_300x250',
}

const tagDefinition = {
  [AdTagName.IC_TickerInContent]: { placements: 3, style: { width: 970, minHeight: 250 } },
  [AdTagName.IC_MapsLeftRail]: { placements: 2, style: { width: 125, minHeight: 125 } },
  [AdTagName.IC_Statements]: { placements: 1, style: { width: 300, minHeight: 60 } },
  [AdTagName.IC_Groups]: { placements: 1, style: { width: 336, minHeight: 280 } },
}

const hasInvestingChannelAds = tracking.getAdsProvider() === tracking.AdsProvider.InvestingChannel

/**
 * Get create/destroy methods to control ads insertion
 */
export function getInvestingChannelPage() {
  var page: ICPageReturn

  function create() {
    try {
      if (hasInvestingChannelAds) {
        window.InvestingChannelQueue?.push(() => {
          let config = window['FINVIZ_IC_UAT_CONFIG'] ?? {}
          page = window.InvestingChannel?.UAT.Run(IC_PUBLISHER_ID, config)
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  function destroy() {
    try {
      if (hasInvestingChannelAds) {
        window.InvestingChannelQueue?.push(() => {
          page?.destroy()
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  return { create, destroy }
}

/**
 * Hook which recreates ads on deps change (SPA navigation,…)
 */
export function useInvestingChannelAds(deps: unknown[] = []) {
  React.useLayoutEffect(() => {
    const page = getInvestingChannelPage()

    page.create()

    return () => page.destroy()
    // eslint cannot statically check the dependencies and complains about it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return hasInvestingChannelAds
}

interface AdTagProps extends Omit<React.HTMLProps<HTMLDivElement>, 'name'> {
  /**
   * Name of the ad tag
   */
  name: keyof typeof AdTagName

  /**
   * Manually set the placement position for this tag
   *
   * @default 1
   */
  position?: number

  /**
   * Additional class names for the wrapper div
   */
  className?: string
}

export function AdTag({ name, position = 1, ...props }: AdTagProps) {
  const tagName = AdTagName[name]
  const tagProps = tagDefinition[tagName]
  const placementName = `${tagName}_${Math.min(Math.max(position, 1), tagProps.placements)}`

  if (!hasInvestingChannelAds) return null

  return <div {...props} id={placementName} style={{ ...props.style, ...tagProps.style }} />
}
