import { useQuery, useQueryClient } from '@tanstack/react-query'
import * as React from 'react'

import { FALLBACK_PERF_RESPONSE } from '../constants/constants'
import * as api from '../services/api'
import { MapDataRoot, MapSubtypeId, MapTypeId, PerfData } from '../types'
import * as mapUtils from '../utils'
import { getMapBaseData } from '../utils'

export type MapDataHookReturn = ReturnType<typeof useMapData>

/**
 * Hook which fetches map data and perf data from the api
 */
export function useMapData(
  type: MapTypeId,
  subtype: MapSubtypeId,
  mapBaseData?: MapDataRoot,
  initialPerf?: PerfData,
  fetchPerfData = api.mapPerf
) {
  const queryClient = useQueryClient()
  const dataVersion = React.useRef<number>()
  const prevType = React.useRef<MapTypeId>()

  React.useEffect(() => {
    if (mapBaseData) {
      const perfData = mapUtils.parseInitDataAsPerf(mapBaseData)
      queryClient.setQueryData(['mapPerf', type, subtype], perfData)
      prevType.current = type
    } else if (initialPerf) {
      queryClient.setQueryData(['mapPerf', type, subtype], initialPerf)
      prevType.current = type
    }
    // This effect only needs to run on mount, otherwise we’d be overriding the data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const baseDataQuery = useQuery({
    queryKey: ['mapBaseData', type],
    queryFn: async () => getMapBaseData(type) as unknown as Promise<MapDataRoot>,
    initialData: !prevType.current ? mapBaseData : undefined,
    cacheTime: Infinity,
    staleTime: Infinity,
  })

  const perfDataQuery = useQuery({
    queryKey: ['mapPerf', type, subtype],
    queryFn: async () => {
      const data = await fetchPerfData(type, subtype)

      return (
        data ??
        queryClient.getQueryData<PerfData>(['mapPerf', type, subtype]) ?? {
          ...FALLBACK_PERF_RESPONSE,
          version: dataVersion.current,
        }
      )
    },
    // Keep previous data if the map type hasn’t changed
    keepPreviousData: prevType.current === type,
    refetchInterval: () => mapUtils.getMapsRefreshInterval(),
    staleTime: 1000,
    onSuccess(data) {
      if (!dataVersion.current && data) {
        dataVersion.current = data.version
      }

      if (dataVersion.current !== data?.version) {
        window.location.reload()
        return
      }

      prevType.current = type
    },
  })

  const data = React.useMemo(() => {
    if (!perfDataQuery.data || !baseDataQuery.data) return undefined

    const [restoredData] = mapUtils.restoreDataKeys([baseDataQuery.data], perfDataQuery.data)

    return restoredData
  }, [baseDataQuery, perfDataQuery.data])

  return {
    data,
    baseDataQuery,
    perfDataQuery,
  }
}
